var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SidePopup',{attrs:{"title":_vm.title,"loading":_vm.isLoading,"hideCancel":"","routeback":"/fornecedor/comercial/assinaturas"},on:{"close":_vm.onClose},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.assinatura && _vm.assinatura.ativo)?_c('FormButton',{attrs:{"type":"error","text":"Cancelar Assinatura"},on:{"click":function($event){return _vm.onCancel(_vm.assinatura)}}}):_vm._e()]},proxy:true}])},[_c('v-row',{attrs:{"no-gutters":""}},[_c('h3',[_vm._v(_vm._s(_vm.assinatura.numero))]),(_vm.assinatura && _vm.assinatura.ativo)?_c('v-chip',{staticClass:"ml-auto font-weight-bold",attrs:{"color":"success"}},[_vm._v(" Ativo ")]):_c('v-chip',{staticClass:"ml-auto font-weight-bold",attrs:{"color":"error"}},[_vm._v(" Inativo ")])],1),_c('LineDivider'),_c('v-card',{attrs:{"flat":"","outlined":""}},[(_vm.assinatura)?_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Hora da compra")]),_c('td',[_vm._v(_vm._s(_vm._f("formatDateTime")(_vm.assinatura.data_criacao,_vm.getTimeZone)))])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Total da assinatura")]),(
              _vm.assinatura.dados_pedido && _vm.assinatura.dados_pedido.totalpedido
            )?_c('td',[_vm._v(" "+_vm._s(_vm.formataReal(_vm.assinatura.dados_pedido.totalpedido))+" ")]):_c('td',[_vm._v(_vm._s(_vm.formataReal(0)))])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Próxima entrega")]),_c('td',[_vm._v(_vm._s(_vm.assinatura.proxima_entrega))])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Recorrência")]),_c('td',[_vm._v(_vm._s(_vm.getRecorrencia()))])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Termo de uso")]),(
              _vm.assinatura.tipo_assinatura &&
                _vm.assinatura.tipo_assinatura.termo_uso
            )?_c('td',[_vm._v(" "+_vm._s(_vm.assinatura.tipo_assinatura.termo_uso)+" ")]):_c('td')]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Nome do cliente")]),_c('td',[_vm._v(_vm._s(_vm.assinatura.cliente_nome))])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("CPF/CNPJ do cliente")]),_c('td',[_vm._v(_vm._s(_vm.assinatura.cliente_cpf))])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("E-mail do cliente")]),_c('td',[_vm._v(_vm._s(_vm.assinatura.cliente_email))])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Telefone")]),_c('td',[_vm._v(_vm._s(_vm.assinatura.cliente_telefone))])])])]):_vm._e()],1),_c('h4',{staticClass:"mt-10"},[_vm._v("Pedidos vinculados")]),_c('LineDivider'),_c('v-card',{staticClass:"mb-5",attrs:{"flat":"","outlined":""}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Número pedido")]),_c('th',[_vm._v("Data do pedido")]),_c('th',{staticClass:"text-center"},[_vm._v("Status")]),_c('th')])]),(_vm.assinatura.pedidos && _vm.assinatura.pedidos.length > 0)?_c('tbody',_vm._l((_vm.assinatura.pedidos),function(p,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(p.numpedido))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(p.datapedido)))]),_c('td',{staticClass:"text-center"},[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":_vm.getStatusPedidoColor(p.statuspedido)}},[_vm._v(" "+_vm._s(_vm.getStatusPedido(p.statuspedido))+" ")])],1),_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('a',{attrs:{"href":_vm.getOrderUrl(p.numpedido)}},[_c('v-icon',_vm._g({attrs:{"color":"primario"}},on),[_vm._v("fa-info-circle")])],1)]}}],null,true)},[_vm._v(" Exibir informações do pedido ")])],1)])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"4"}},[_vm._v("Nenhum pedido encontrado")])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }