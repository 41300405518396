const OrderStatusFilter = [
  { text: 'Todos', value: 'TODOS' },
  { text: 'Orçamentos novos', value: 'ORCAMENTO_NOVO' },
  { text: 'Orçamentos aceitos', value: 'ORCAMENTO_PRECIFICADO' },
  { text: 'Novos pedidos', value: 'ENVIADO' },
  { text: 'Em separação', value: 'SEPARANDO' },
  { text: 'Pronto para entrega', value: 'PAGO' },
  { text: 'Em entrega', value: 'EMENTREGA' },
  { text: 'Entregues', value: 'ENTREGUE' },
  { text: 'Cancelados', value: 'CANCELADO' },
  { text: 'Pronto para retirada', value: 'PRONTO_RETIRADA' },
  { text: 'Aguardando Pagamento', value: 'AGUARDANDOPAGAMENTO' }
]

const OrderDeliveryType = [
  { text: 'Todos', value: 'TODOS' },
  { text: 'Delivery', value: 'DELIVERY' },
  { text: 'Retirada', value: 'RETIRADA' }
]

const OrderStatusLabel = {
  ORCAMENTO_NOVO: 'Em análise',
  ORCAMENTO_PRECIFICADO: 'Orçado',
  ENVIADO: 'Pedido recebido',
  SEPARANDO: 'Preparando seu pedido',
  AGUARDANDOPAGAMENTO: 'Aguardando pagamento',
  PAGO: 'Pedido pronto para entrega',
  PRONTO_RETIRADA: 'Pronto para retirada',
  EMENTREGA: 'Pedido saiu para entrega',
  ENTREGUE: 'Pedido entregue',
  CANCELADO: 'CANCELADO'
}

const OrderStatusColor = {
  ORCAMENTO_NOVO: 'success',
  ORCAMENTO_PRECIFICADO: 'success',
  ENVIADO: 'success',
  SEPARANDO: 'primary',
  AGUARDANDOPAGAMENTO: 'warning',
  PAGO: 'warning',
  PRONTO_RETIRADA: 'primary',
  EMENTREGA: 'primary',
  ENTREGUE: 'primario',
  CANCELADO: 'error'
}

export default {
  OrderStatusFilter,
  OrderDeliveryType,
  OrderStatusLabel,
  OrderStatusColor
}
