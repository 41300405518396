<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    :loading="isLoading"
    hideCancel
    routeback="/fornecedor/comercial/assinaturas"
  >
    <v-row no-gutters>
      <h3>{{ assinatura.numero }}</h3>
      <v-chip
        color="success"
        v-if="assinatura && assinatura.ativo"
        class="ml-auto font-weight-bold"
      >
        Ativo
      </v-chip>
      <v-chip color="error" v-else class="ml-auto font-weight-bold">
        Inativo
      </v-chip>
    </v-row>
    <LineDivider></LineDivider>
    <v-card flat outlined>
      <v-simple-table v-if="assinatura">
        <tbody>
          <tr>
            <td class="font-weight-bold">Hora da compra</td>
            <td>{{ assinatura.data_criacao | formatDateTime(getTimeZone) }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Total da assinatura</td>
            <td
              v-if="
                assinatura.dados_pedido && assinatura.dados_pedido.totalpedido
              "
            >
              {{ formataReal(assinatura.dados_pedido.totalpedido) }}
            </td>
            <td v-else>{{ formataReal(0) }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Próxima entrega</td>
            <td>{{ assinatura.proxima_entrega }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Recorrência</td>
            <td>{{ getRecorrencia() }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Termo de uso</td>
            <td
              v-if="
                assinatura.tipo_assinatura &&
                  assinatura.tipo_assinatura.termo_uso
              "
            >
              {{ assinatura.tipo_assinatura.termo_uso }}
            </td>
            <td v-else></td>
          </tr>
          <tr>
            <td class="font-weight-bold">Nome do cliente</td>
            <td>{{ assinatura.cliente_nome }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">CPF/CNPJ do cliente</td>
            <td>{{ assinatura.cliente_cpf }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">E-mail do cliente</td>
            <td>{{ assinatura.cliente_email }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Telefone</td>
            <td>{{ assinatura.cliente_telefone }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <h4 class="mt-10">Pedidos vinculados</h4>
    <LineDivider></LineDivider>
    <v-card flat outlined class="mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <th>Número pedido</th>
            <th>Data do pedido</th>
            <th class="text-center">Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="assinatura.pedidos && assinatura.pedidos.length > 0">
          <tr v-for="(p, i) in assinatura.pedidos" :key="i">
            <td>{{ p.numpedido }}</td>
            <td>{{ p.datapedido | formatDate }}</td>
            <td class="text-center">
              <v-chip
                :color="getStatusPedidoColor(p.statuspedido)"
                class="font-weight-bold"
              >
                {{ getStatusPedido(p.statuspedido) }}
              </v-chip>
            </td>
            <td class="text-center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <a :href="getOrderUrl(p.numpedido)">
                    <v-icon v-on="on" color="primario">fa-info-circle</v-icon>
                  </a>
                </template>
                Exibir informações do pedido
              </v-tooltip>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4" class="text-center">Nenhum pedido encontrado</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <template v-slot:buttons>
      <FormButton
        v-if="assinatura && assinatura.ativo"
        type="error"
        text="Cancelar Assinatura"
        @click="onCancel(assinatura)"
      />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormButton from '@/components/form/FormButton'
import LineDivider from '@/components/LineDivider'
import { RECORRENCIA } from '@/store/actions/assinatura'
import { ASSINATURA_LOAD, ASSINATURA_REMOVE } from '@/store/actions/assinatura'
import * as _ from 'lodash'
import OrderStatus from '../../../utils/orderStatus'

export default {
  name: 'AcompanhamentoForm',
  components: {
    SidePopup,
    FormButton,
    LineDivider
  },
  data: () => ({
    isLoading: false,
    assinatura: {}
  }),
  computed: {
    title() {
      return 'Detalhes da assinatura'
    }
  },
  methods: {
    onClose(routeBack = false) {
      this.isLoading = false
      this.$emit('close')
      if (routeBack) {
        this.$router.replace('/fornecedor/cadastro/calendarios')
      }
    },
    onCancel(item) {
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma o cancelamento da assinatura '${item.numero}'?`
      }
      this.confirmSwal(options, () => this.cancel(item))
    },
    cancel(item) {
      this.isLoading = true
      this.$store
        .dispatch(ASSINATURA_REMOVE, item.idassinatura)
        .then(() => {
          this.$vueOnToast.pop('success', 'Assinatura cancelada com sucesso')
          this.getData(item.idassinatura)
          this.isLoading = false
        })
        .catch(err => {
          const erro = _.get(
            err,
            'responseJSON.message',
            'Não foi possível cancelar a assinatura'
          )
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    getData(idassinatura) {
      this.isLoading = true
      this.$store
        .dispatch(ASSINATURA_LOAD, idassinatura)
        .then(data => {
          this.assinatura = data
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.onClose()
        })
    },
    getRecorrencia() {
      const tipoAssinatura =
        this.assinatura.tipo_assinatura &&
        this.assinatura.tipo_assinatura.recorrencia
          ? this.assinatura.tipo_assinatura.recorrencia
          : this.assinatura.tipo_assinatura &&
            this.assinatura.tipo_assinatura.value
          ? this.assinatura.tipo_assinatura.value
          : ''
      return RECORRENCIA[tipoAssinatura] ? RECORRENCIA[tipoAssinatura].name : ''
    },
    formataReal(valor) {
      return (
        'R$ ' +
        parseFloat(valor).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      )
    },
    getOrderUrl(number) {
      return `/fornecedor/pedidos/pedido?numped=${number}`
    },
    getStatusPedido(status) {
      return OrderStatus && OrderStatus.OrderStatusLabel
        ? OrderStatus.OrderStatusLabel[status]
        : ''
    },
    getStatusPedidoColor(status) {
      return OrderStatus && OrderStatus.OrderStatusColor
        ? OrderStatus.OrderStatusColor[status]
        : ''
    }
  },
  created() {
    let { idassinatura } = this.$route.params
    this.getData(idassinatura)
  }
}
</script>
